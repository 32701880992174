<template>
  <div>
    <TagbyFilterV2
      :initialParams="filterParams"
      :categoryOptions="categoryOptions"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'app-manage-widget-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="currentPage"
      :items="items"
      :fields="tableColumns"
      :busy="isBusy"
      :current-page="currentPage"
      :total-rows="totalRows"
      :per-page="perPage"
      @page-change="changePage"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'app-manage-widget-detail', params: { idx: data.value } }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(state)="data">
        <div
          style="width: 80px"
          :class="data.value === 'ACTIVE' ? 'text-success' : null"
        >
          {{ data.value }}
        </div>
      </template>

      <template #cell(start_at)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(end_at)="data">
        <TagbyDateColumn :value="data.value" />
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import {
  BLink,
  BButton,
} from 'bootstrap-vue'
import useInitialize from './useInitialize'
import useItems from './useItems'
import TagbyFilterV2 from '@/components/TagbyFilterV2.vue'
import TagbyList from '@/components/TagbyList.vue'
import TagbyDateColumn from '@/components/TagbyDateColumn.vue'
import TagbyDatetimeColumn from '@/components/TagbyDatetimeColumn.vue'

export default defineComponent({
  components: {
    TagbyFilterV2,
    BLink,
    BButton,
    TagbyList,
    TagbyDateColumn,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
    } = useItems()
    fetchItems()

    return {
      items,
      fetchItems,
      categoryOptions,
      filterParams,
      search,

      tableColumns,
      currentPage,
      perPage,
      totalRows,
      isBusy,
      changePage,
    }
  },
})
</script>
